<template>
  <div>
    <div class="scoped-container">
      <div class="header-container">
        <p class="time"> {{ moment((appointment.clinic?.attendance_type === 'ARRIVAL' && appointment?.status_time) || appointment.start_datetime).format('HH[h]mm') }}</p>
        <DescriptionIcon :id="appointment.id" />
        <b-popover :target="appointment.id" triggers="hover" placement="bottom" class="items-container">
        <Tooltip
          :content="{ title: appointment.patient?.name }"
          :data="appointment"
          :hasProcedure="() => false"/>
        </b-popover>
      </div>

      <div class="patient-container">
        <Avatar 
          margin="0 10px 0 0" 
          :src="`${appointment.patient.picture}?x=${moment()}`" 
          :status="appointment.status" 
        />
        <Ellipsis class="name">
          {{ appointment?.patient?.name }}
        </Ellipsis>
      </div>

      <div>
        <div class="procedures-container" v-for="appointment_item in appointment.appointment_items" :key="appointment_item.id">
            <div>
              <span>
                <ProcedureIcon v-if="appointment_item.item.type === 'PROCEDURE'" :class="`icon stroke procedure-icon`" />
                <TelemedicineIcon v-if="appointment_item.item.type === 'TELEMEDICINE'" :class="`icon stroke procedure-icon`" />
                <AppointmentIcon v-if="appointment_item.item.type === 'APPOINTMENT'" :class="`icon stroke procedure-icon`" />
                <ReturnIcon v-if="appointment_item.item.type === 'RETURN'" :class="`icon stroke procedure-icon`" />
                <ExamIcon v-if="appointment_item.item.type === 'EXAM'" class="exam" :class="`icon stroke procedure-icon`" />
                <SurgicalIcon v-if="appointment_item.item.type === 'SURGICAL'" class="icon stroke surgical-icon" />
              </span>
              <span v-if="appointment_item.item.type === 'EXAM'">{{ appointment_item?.item?.procedure?.name ?? appointment_item?.item?.name }}</span>
              <span v-else>{{ parseProcedureType(appointment_item.item.type) }}</span>
            </div>
            <a v-if="appointment_item.item?.deleted_at && moment(appointment_item.item?.deleted_at).format('YYYY-MM-D') >= moment().format('YYYY-MM-D')" class="custom-invalid-feedback">
              <Ellipsis>Item acima excluído. Não estará disponível na pré-consulta e exames/procedimentos. Por favor, atualize o item no agendamento para habilitá-lo e realizar o atendimento.</Ellipsis>
            </a>
        </div>
      </div>

      <div class="buttons">
        <b-button
          variant="link"
          class="fs-14 p-0 mr-4"
          @click="onStartClick(appointment)"
          :disabled="appointment?.status === 'IN_PRE_CONSULTATION' || !validProcedure(appointment)"
        >
          Iniciar consulta
        </b-button>

        <router-link
          :to="'pacientes/' + appointment.patient.id">
          <b-button variant="link" class="fs-14 p-0">
            Ver perfil
          </b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
  export default {
    name: 'TodaysAgenda',
    components: {
      AppointmentIcon: () => import('@/assets/icons/appointment.svg'),
      Avatar: () => import('@/components/General/Avatar'),
      DescriptionIcon: () => import('@/assets/icons/new-description.svg'),
      Ellipsis: () => import('@/components/General/Ellipsis'),
      ExamIcon: () => import('@/assets/icons/exam.svg'),
      ProcedureIcon: () => import('@/assets/icons/procedure.svg'),
      ReturnIcon: () => import('@/assets/icons/return.svg'),
      SurgicalIcon: () => import('@/assets/icons/surgical.svg'),
      TelemedicineIcon: () => import('@/assets/icons/telemedicine.svg'),
      Tooltip: () => import('@/modules/schedule/components/Tooltip')
    },
    props: {
      appointment: {
        type: Object,
        required: true,
      },
      tooltipId: String,
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        procTissType: 'App\\Models\\TissGuide\\TissTableProcedure'
      }
    },
    methods: {
      parseProcedureType(type) {
        const getProcedureType = {
          'PROCEDURE': 'Procedimento',
          'TELEMEDICINE': 'Telemedicina',
          'APPOINTMENT': 'Presencial',
          'RETURN': 'Retorno',
          'EXAM': 'Exame',
          'SURGICAL': 'Centro Cirúrgico',
          default: type
        }
        return getProcedureType[type] || getProcedureType.default
      },
      getStatusLabel (status) {
        const statusLabel = {
          'ATTENDED': 'Compareceu',
          'FINISHED': 'Atendimeno finalizado',
          'CONFIRMED': 'Confirmado',
          'CONFIRMED_BY_PATIENT': 'Confirmado pelo paciente',
          'MISSED': 'Faltou',
          'CANCELLED': 'Cancelado',
          'CANCELLED_BY_PATIENT': 'Cancelado pelo paciente',
          'WAITING': 'Aguardando atendimento',
          'HAPPENING': 'Em atendimento médico',
          'EXAMINATING': 'Em exames',
          'DILATING': 'Dilatando',
          'PRE_FINISHED': 'Pré-consulta finalizada',
          'IN_PRE_CONSULTATION': 'Em pré-consulta',
          default: 'Situação desconhecida'
        }
        return statusLabel[status] || statusLabel.default
      },
      goTo(path) {
        this.$router.push(path)
      },
      onStartClick(appointment) {
        if (['MISSED', 'CANCELLED', 'CANCELLED_BY_PATIENT', 'FINISHED'].includes(appointment.status)) {
          this.$toast.warning('O status deste agendamento não permite iniciar um atendimento')
          return
        }

        // commented to start always as attendance
        // if (appointment.appointment_items.some(appointmentItem => appointmentItem.item?.type === 'EXAM')) {
        //   this.startExam(appointment)
        //   return
        // }

        this.handleStartAttendance(appointment)
      },

      // startExam not being used
      async startExam(appointment) {
        const appointmentItem = appointment.appointment_items.find(el => el.item.type === 'EXAM')
        const res = await this.api.getExamByAppointmentAndClinicProcedure(appointment.id, appointmentItem.item_id)
        this.$router.push(`exames/${res.data.id}`);
      },
      handleStartAttendance (appointment) {
        const attendance = appointment.attendance.find(att => att.type === 'ATTENDANCE')

        if (attendance && appointment?.status === 'HAPPENING') {
          this.goTo(`/pacientes/${appointment.patient.id}/consulta/${attendance?.id}`)
        }
        
        if (attendance && appointment?.status === 'IN_PRE_CONSULTATION') {
          const preConsultation = appointment.attendance.find(att => att.type === 'PRE_CONSULTATION')
          this.goTo(`/pacientes/${appointment.patient.id}/pre-consulta/${preConsultation?.id}`)
        }

        this.startAttendance(appointment)
      },
      async startAttendance(appointment) {
        const isLoading = this.$loading.show()
        const formId = await this.getDefaultForm()
        this.api
          .startAttendance(this.clinic.id, appointment.patient.id, appointment.id, formId)
          .then(res => {
            const resData = res.data
            this.$router.push(`/pacientes/${appointment.patient.id}/consulta/${resData.id}`)
          })
          .catch(err => {
            if (err.response && err.response.status === 422) {
              this.$toast.error(err.response.data.error)
              this.$router.push(`/pacientes/${appointment.patient.id}/consulta/${err.response.data.id}`)
            }
            else{
              this.$toast.error(err.message)
            }
          })
          .finally(() => {
            isLoading.hide()
          })
      },
      async getDefaultForm() {
        const user = JSON.parse(localStorage.getItem('user'))
        try {
          const response = await this.api.getForms(this.clinic.id, user.id, 'ATTENDANCE');
          const form = response.data.find(f => f.default);
          let defaultForm = null;
          if (form) {
            defaultForm = form.id;
          }
          return defaultForm

        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      validProcedure(appointment){
        if(appointment?.appointment_items){
          return !appointment?.appointment_items.find(a => a?.item?.deleted_at !== null)
        }
        return true
      },
    },
  }
</script>

<style lagn="scss">
  .tooltip-inner {
    text-align: left !important;
  }
</style>

<style lang="scss" scoped>
  .header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: start;

    svg {
      margin-left: 16px;
      line-height: 1;
      cursor: help;
    }
  }

  .scoped-container {
    padding: 0;
    margin: 0;
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .patient-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .procedures-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--type-placeholder);

    .procedures-align {
      display: flex;
      align-items: center;

    }

    svg {
      width: 16px;
      height: 16px;
      fill: var(--type-placeholder);
      margin-right: 8px;

      &.surgical-icon path {
        fill: var(--type-placeholder);
      }
    }

    span {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

</style>
